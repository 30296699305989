import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import Note from '../Note';
function NoteList() {
    const iconList = [
        [<CheckCircleIcon />, 'Website And Social Media Release'],
        [<PendingIcon />, 'CoinGecko Listing'],
        [<PendingIcon />, 'CoinMarketCap Listing'],
        [<PendingIcon />, 'Partnership Announcements'],
        [<PendingIcon />, 'NFT + DeFi'],
        [<PendingIcon />, 'Website Upgrade'],
        [<PendingIcon />, 'Create TUTU NFT Farm'],
        [<CheckCircleIcon />, 'Marketing Push'],
        [<PendingIcon />, 'VIP TUTU Club'],
        [<CheckCircleIcon />, 'Donation Compaign'],
        [<CheckCircleIcon />, 'New Top Exchange Launch'],
        [<CheckCircleIcon />, 'TUTU Merchandise'],
    ]
    return (
        <ul className='noteList'>
            {
                iconList.map(icon => {
                    return <li>
                        <Note Icon={icon[0]} text={icon[1]} />
                    </li>
                })
            }
        </ul>
    )
}

export default NoteList
import React from 'react'

const Address = ({ width }) => {
    const addr = '0x5fc8a176ceccb9d65779804f17632ce6571fbe29'
    return (
        <div className="address-wrapper" style={{ display: width > 1000 ? 'block' : 'none' }}>
            <input type="text" disabled={true} placeholder={addr} />
        </div>
    )
}

export default Address
import React from 'react'
import tutu from '../../imgs/tutu.jpg'
function About() {

    const text = "TUTU is an experiment in decentralized \
    spontaneous community building. TUTU token \
    is our first token and allows users to hold \
    Billions or even Trillions of them. TUTU is a \
    token on Binance Smart Chain boasting a \
    number of impressive features. And finally, \
    TUTU has massive decentralization on a scale \
    rarely seen in other tokens.Anyone who has \
    skills or time they can contribute to Tutu will be \
    invited to help develop and advance Tutu. This \
    group has so far responsible for logo \
    creation, social media presence, marketing \
    and website development. Tutu community is \
    the beating heart of this project and will \
    continue to evolve and expand."

    return (
        <div className='about-wrapper'>
            <div className="about-title">
                <div className="t1">About</div>
                <div className="t2">TUTU Token<br /></div>
            </div>
            <div className="about-text">
                <p>{text}</p>
            </div>
            <div className="about-avatar">
                <img src={tutu} alt="tutu avatar" />
            </div>
        </div>
    )
}

export default About
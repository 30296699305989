import React from 'react'
import babydoge from '../../imgs/babydoge.svg'
import dogecoin from '../../imgs/dogecoin.svg'
import shiba from '../../imgs/shiba.svg'

function Partner() {
    return (
        <div className='partner-wrapper'>
            <div className="partner-title">
                <div className="t1">OUR</div>
                <div className="t2">PARTNERS<br /></div>
            </div>
            <div className="partner-avatar-container">
                <img src={dogecoin} alt="dogecoin" />
            </div>
            <div className="partner-avatar-container">
                <img src={shiba} alt="shiba" />
            </div>
            <div className="partner-avatar-container">
                <img src={babydoge} alt="babydoge" />
            </div>
        </div>
    )
}

export default Partner
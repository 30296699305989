import "./App.css";
import IntroList from "./components/IntroList";
import About from "./components/About";
import Advantage from "./components/Advantage";
import Farm from "./components/Farm";
import Roadmap from "./components/Roadmap";
import Partner from "./components/Partner";
import Cover from "./components/Cover";
function App() {
  return (
    <div className="App">
      <Cover />
      <div className="intro-about-group">
        <IntroList />
        <About />
      </div>
      <Advantage />
      <Farm />
      <Roadmap />
      <Partner />
    </div>
  );
}

export default App;

import React from 'react'
import rocket from '../../imgs/rocket.png'
function Farm() {
    const text = "TUTU token will launch DeFi and NFT markets in \
    the next two years. In DeFi, Tutu will be the \
    mother currency. When you stake your tokens, \
    you earn points which can be used to select \
    the NFTs you'd like. There will be many more \
    NFTs uploaded over time, including tons from \
    our partners! So it's a good idea to start \
    staking your tokens right away and start \
    earning points. As we form new partnerships, \
    you will also have the ability to earn NFTs from \
    different Binance Smart Chain coins you might \
    hold. Our TUTU Bank is stuffed with benefits for \
    you to enjoy!"
    return (
        <div className='farm-wrapper'>
            <div className="farm-content">
                <div className="farm-title">
                    <div className="t1">TUTU Token</div>
                    <div className="t2">NFT&DEFI FARM<br /></div>
                </div>
                <div className="farm-text">
                    {text}
                </div>
            </div>
            <div className="farm-image">
                <img src={rocket} alt="rocket" />
                <div className="farm-button" onTouchStart={() => { }}>
                    <a>NFT FARM</a>
                </div>
            </div>
        </div>
    )
}

export default Farm
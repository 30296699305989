import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import cover from '../../imgs/cover.png'
import cover_2 from '../../imgs/cover_2.jpeg'
import Address from '../Address'

function Cover() {
    const [width, setWidth] = useState(0)
    const addr = '0x5fc8a176ceccb9d65779804f17632ce6571fbe29'

    const resizeUpdate = (e) => {
        setWidth(e.target.innerWidth)
    }

    useEffect(() => {
        let width = window.innerWidth;
        setWidth(width)
        window.addEventListener('resize', resizeUpdate);
        return () => {
            window.removeEventListener('resize', resizeUpdate);
        }
    }, []);

    return (
        <div className="cover-wrapper">
            <img src={width > 900 ? cover_2 : cover} alt="cover" />
            <CopyToClipboard text={addr} onCopy={() => {
                alert("Address Copied");
            }}>
                <div className="cover-button" onTouchStart={() => { }}>
                    <p>BUY NOW</p>
                </div>
            </CopyToClipboard>
            <Address width={width} />
        </div>
    )
}

export default Cover
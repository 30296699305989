import React from 'react'

function Card({ Icon, title, text }) {
    return (
        <div className='card-wrapper' onTouchStart={() => { }}>
            <div className="card-icon">
                {Icon}
            </div>
            <div className="card-title">
                <h3>{title}</h3>
            </div>
            <div className="card-text">
                <p>{text}</p>
            </div>
        </div>
    )
}

export default Card
import React from 'react'
import Card from '../Card';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import DiamondIcon from '@mui/icons-material/Diamond';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PaymentsIcon from '@mui/icons-material/Payments';
function Advantage() {
    const text = 'The Black Hole owned over 50% of supply at launch'
    const iconList = [
        [<LocalAtmIcon />, 'Unruggable', 'Liquidity is locked in PancakeSwap'],
        [<CurrencyExchangeIcon />, 'Fair Launch', '100% of the TUTU is in circulation'],
        [<WhatshotIcon />, 'No Inflation', 'One hundred trillion .5% Airdrop 50% black hole, 10% is reserved for'],
        [<DiamondIcon />, '100% Decentralized', 'Tutu token is community Led and managed by volunteers'],
        [<ShowChartIcon />, 'Continuously Rising Price', 'Starting from 1000, the number of TUTU holders increases by one zero, and we will destroy 1% until we destroy 5%'],
        [<PaymentsIcon />, 'Long Term Investment', "If you can't hold you won't be rich"]
    ]
    return (
        <div className='advantage-wrapper'>
            <div className="advantage-title">
                <div className="t1">Black Hole</div>
                <div className="t2">TUTU Advantage<br /></div>
            </div>
            <div className="advantage-text">
                {text}
            </div>
            <ul className='advList'>
                {
                    iconList.map(icon => {
                        return (
                            <li>
                                <Card Icon={icon[0]} title={icon[1]} text={icon[2]} />
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Advantage
import React from 'react'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
function Note({ Icon, text }) {
    return (
        <div className='note-wrapper' onTouchStart={() => { }}>
            <div className="note-avatar">
                {Icon}
            </div>
            <div className="note-text">
                <p>{text}</p>
            </div>
            <div className="note-link">
                <ArrowCircleRightOutlinedIcon />
            </div>
        </div>
    )
}

export default Note
import React from 'react'
import NoteList from '../NoteList'
function Roadmap() {
    return (
        <div className='roadmap-wrapper'>
            <div className="roadmap-title">
                <div className="t1">TUTU</div>
                <div className="t2">ROADMAP<br /></div>
            </div>
            <NoteList />
        </div>
    )
}

export default Roadmap
import React from 'react'
import Card from '../Card';
import PaidIcon from '@mui/icons-material/Paid';
import TelegramIcon from '@mui/icons-material/Telegram';
import HandshakeIcon from '@mui/icons-material/Handshake';
import TwitterIcon from '@mui/icons-material/Twitter';
function IntroList() {
    const iconList = [
        [<PaidIcon />, 'Over 51.3%', 'Of supply held by the Black Hole'],
        [<TelegramIcon />, 'Over 4500+', 'Community Member'],
        [<HandshakeIcon />, 'Over 4000+', 'TUTU Token Holders'],
        [<TwitterIcon />, 'Over 4800+', 'International followers']
    ]
    return (
        <ul className='introList-wrapper'>
            {
                iconList.map(icon => {
                    return (
                        <li>
                            <Card Icon={icon[0]} title={icon[1]} text={icon[2]} />
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default IntroList